import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'

const ConsentModal = ({ children }) => {
  const [consent, setConsent] = useState(null)
  const [show, setShow] = useState(false)
  const handleAgree = () => {
    setShow(false)
    setConsent(true)
    sessionStorage.setItem('gdpr-consent', true)
  }
  const handleDisagree = () => {
    setShow(false)
    setConsent(false)
    sessionStorage.setItem('gdpr-consent', false)
  }

  React.useEffect(() => {
    const sessionConsent = sessionStorage.getItem('gdpr-consent')

    if (sessionConsent === true) {
      setConsent(true)
    } else if (sessionConsent === false) {
      setConsent(false)
    } else if (sessionConsent === null) {
      setShow(true)
    }
  }, [])

  return (
    <>
      {consent === true && (
        <Helmet>
          <script
            async
            src='https://www.googletagmanager.com/gtag/js?id=UA-138594568-1'
          ></script>
          <script>
            {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-138594568-1');`}
          </script>
        </Helmet>
      )}
      <Modal
        show={show}
        onHide={handleAgree}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Cookie Consent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Will Centre understands that your privacy is important to you and that
          you care about how your personal data is used. We respect and value
          the privacy of everyone who visits this website and will only collect
          and use personal data described further in our{' '}
          <Link to={'/privacy'}>Privacy Policy</Link> and in a way that is
          consistent with our obligations and your rights under the law. Do you
          consent to third party cookies?
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleAgree}>
            I Agree
          </Button>
          <Button variant='primary' onClick={handleDisagree}>
            I Disagree
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ConsentModal
