import { Link } from 'gatsby'
import * as React from 'react'

const Footer = () => {
  return (
    <footer className='bg-primary py-5 container-fluid'>
      <div className='row justify-content-center text-center'>
        <div className='col-md-3 footer-col'>
          <h3>Services</h3>
          <div className='row'>
            <Link to={'/wills'}>Wills</Link>
          </div>
          <div className='row'>
            <Link to={'/trusts'}>Trusts</Link>
          </div>
          <div className='row'>
            <Link to={'/lasting-power-of-attorney'}>
              Lasting Power of Attorney
            </Link>
          </div>
          <div className='row'>
            <Link to={'/property-ownership'}>Property Ownership</Link>
          </div>
        </div>

        <div className='col-md-3 footer-col'>
          <h3>Contact</h3>
          <p>
            Tel: <a href='tel:03300100919'>0330 010 0919</a>
            <br />
            Email:{' '}
            <a href='mailto:info@willcentre.co.uk'>info@willcentre.co.uk</a>
          </p>
        </div>
        <div className='col-md-3 footer-col'>
          <h3>Postal Address</h3>
          <p>
            94 New Walk, 2nd Floor <br />
            Leicester, LE1 7EA <br />
            England, United Kingdom
          </p>
        </div>
      </div>
      <div className='row justify-content-center'>
        <div className='col-auto text-center'>
          <Link to={'/privacy'}>Privacy Policy</Link>
        </div>
      </div>
      <div className='row justify-content-center '>
        <div className='col-auto text-center'>
          <p> &copy; Will Centre 2019 - {new Date().getFullYear()}</p>
          <p className='text-muted'>
            Will Centre is a trading style of Will & Estate Planning Centre
            Limited. Registered in England & Wales, Registration No 11888978.
            Registered address: 94 New Walk, 2nd Floor, Leicester, United
            Kingdom, LE1 7EA
          </p>
        </div>
      </div>
    </footer>
  )
}
export default Footer
