import * as React from 'react'
import { Link } from 'gatsby'
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StaticImage } from 'gatsby-plugin-image'

const Header = () => {
  return (
    <nav
      className='navbar navbar-expand-lg navbar-light bg-white fixed-top shadow'
      aria-label='navbar'
    >
      <div className='container fluid'>
        <div className='navbar-brand d-flex my-auto'>
          <Link to={'/'}>
            <StaticImage
              src='../images/logo-blue.png'
              alt='WillCentre Logo'
              placeholder='blurred'
              width={250}
              formats={['auto', 'webp', 'avif']}
              quality={100}
            ></StaticImage>
          </Link>
        </div>
        <button
          className='navbar-toggler collapsed'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbar'
          aria-controls='navbar'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>

        <div className='collapse navbar-collapse' id='navbar'>
          <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
            <li className='nav-item'>
              <Link to={'/'} className='nav-link'>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link to={'/about-us'} className='nav-link'>
                About Us
              </Link>
            </li>
            <li className='nav-item dropdown'>
              <Link
                to='#'
                className='nav-link dropdown-toggle'
                id='dropdown-main'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                Services
              </Link>

              <ul className='dropdown-menu' aria-labelledby='dropdown-main'>
                <li>
                  <Link to={'/wills'} className='dropdown-item'>
                    Wills
                  </Link>
                </li>
                <li>
                  <Link to={'/trusts'} className='dropdown-item'>
                    Trusts
                  </Link>
                </li>
                <li>
                  <Link
                    to={'/lasting-power-of-attorney'}
                    className='dropdown-item'
                  >
                    Lasting Power of Attorney
                  </Link>
                </li>
                <li>
                  <Link to={'/property-ownership'} className='dropdown-item'>
                    Property Ownership
                  </Link>
                </li>
              </ul>
            </li>
            <li className='nav-item'>
              <Link to={'/articles'} className='nav-link'>
                Articles
              </Link>
            </li>
            <li className='nav-item'>
              <Link to={'/contact'} className='nav-link'>
                Contact
              </Link>
            </li>
          </ul>

          <div className='d-flex'>
            <div className='row justify-content-center'>
              <div className='col-auto'>
                <span className='me-3'>
                  <a
                    href='https://www.facebook.com/WillCentreUK'
                    target='blank_'
                  >
                    <FontAwesomeIcon
                      icon={faFacebook}
                      size={'2x'}
                      className='social-link'
                    ></FontAwesomeIcon>
                  </a>
                </span>
                <span className='me-3'>
                  <a
                    href='https://instagram.com/the_will_centre'
                    target='blank_'
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      size={'2x'}
                      className='social-link'
                    ></FontAwesomeIcon>
                  </a>
                </span>
                <span className='me-3'>
                  <a href='https://twitter.com/WillCentre1' target='blank_'>
                    <FontAwesomeIcon
                      icon={faTwitter}
                      size={'2x'}
                      className='social-link'
                    ></FontAwesomeIcon>
                  </a>
                </span>
                <span className='me-3'>
                  <a
                    href='https://www.linkedin.com/company/willcentre'
                    target='blank_'
                  >
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      size={'2x'}
                      className='social-link'
                    ></FontAwesomeIcon>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header
